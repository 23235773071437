import { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import { NextSeo } from "next-seo";
import Script from "next/script";
import dynamic from "next/dynamic";
import TaboolaAdContainer from "./adContainer/taboolaContainer";
import GoogleAdContainer from "./adContainer/googleAdContainer";
import { useWindowSize } from "api/commonConstants";
import MainContainer from "./mainContainer";
import breadcrumbSchema from "../../constants/websiteSchema/breadcrumb.json";
import ListingPage from "components/liveblog/listingPage";
// import { baseUrl } from "api/constants";
import VisualStories from "./CategoryPosts/visualStories";
import ElectionsWidget from "components/elections-2023/ElectionsWidget";
// import AuthorColumn from "./authorColumn";
const BlogWidget = dynamic(() => import("components/liveblog/blogWidget"));
import { getLanguage } from "localization/i8intl";
import { FormattedMessage } from "react-intl";
import FAQComponent from "components/faq";
import { MasterConfigContext } from "constants/MasterConfigContext";
// import Sponsor from "components/Sponsor";
// import getConfig from "next/config";
// import CricketSlider from "./cricketSlider";
// import LiveShow from "./liveShow/liveShow";
// import {
//   fetchAndActivate,
//   getRemoteConfig,
//   getString,
// } from "firebase/remote-config";
// import app from "utils/firebase";
import {
  // apstagBidCallback,
  // createApsSlotsRosMobile,
  // createApsSlotsRosDesktop,
  requestBiddingFromPWT,
} from "./../Ads/index";

const ShortsSlider = dynamic(() =>
  import("../short-video/shortSlider.js", { ssr: true })
);
const TopShows = dynamic(() => import("./topShows/topShows.js", { ssr: true }));
const TopShowsWidget = dynamic(() =>
  import("./topShows/topShowsWidget.js", { ssr: true })
);
const CategoryPosts = dynamic(
  () => import("./CategoryPosts/categoryPosts.js"),
  { ssr: true }
);

const HomePage = ({
  featuredPosts = [],
  bannerData = [],
  liveShowData = [],
  shortsData = [],
  topShowsData = [],
  NewsPageData = [],
  // cricketResponse = [],
  quizHomeData = [],
  electionTopicData = [],
  topShowsWidgetData = [],
  responseVs = {},
  liveBlogWidgetData = [],
  homePageVideosData = [],
  authorWidgetData = [],
  topPodcastData = [],
}) => {
  // liveBlogWidgetData = [];
  const masterConfig = useContext(MasterConfigContext);

  const STATIC_BREADCRUMP = [
    {
      displayText: "कौन जीता-कौन हारा",
      url: "/election/maharashtra-assembly-chunav/results",
      keyToShow: "Maharashtra_KaunJeeta",
    },
    {
      displayText: "प्रमुख उम्मीदवार",
      url: "/election/maharashtra-assembly-chunav/key-candidates",
      keyToShow: "MaharashtraKeyCandidate",
    },

    {
      displayText: "विधान सभा रिजल्ट",
      url: "/election/maharashtra-assembly-chunav/party-wise-result",
      keyToShow: "MaharashtraResultPage",
    },
    {
      displayText: "शेड्यूल",
      url: "/election/maharashtra-assembly-chunav/schedule",
      keyToShow: "MaharashtraSchedulePage",
    },
    {
      displayText: "सामान्य प्रश्न",
      url: "/election/assembly-chunav/faq",
      keyToShow: "",
    },
  ];

  //needs to be changed before deploying to prod
  const masterObj = masterConfig["Maharashtra_vidhansabha_election"];
  console.log("topShowsWidgetData 123 ", masterConfig);

  const router = useRouter();
  const [language, setLanguage] = useState("");
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    setLanguage(getLanguage());
  }, []);
  console.log("responseVs", liveBlogWidgetData);
  const size = useWindowSize();
  console.log("sizeeeee", size);

  const [firstScroll, setFirstScroll] = useState(true);
  const [adCalled, setAdCalled] = useState(false);
  useEffect(() => {
    // var showAds = true;

    // if (process.env.NEXT_PUBLIC_ADS_ONSCROLL === "true" && !firstScroll) {
    //   showAds = false;
    // }
    setTimeout(() => {
      window.googletag = window.googletag || { cmd: [] };
      if (window.screen.width > 600) {
        if (
          router.pathname.includes("/text") ||
          router.pathname.includes("/video")
        ) {
          googletag.cmd.push(function () {
            googletag
              .defineSlot(
                "/1007232/Lallantop_Desktop_ROS_ATF_728x90",
                [
                  [728, 90],
                  [970, 250],
                  [970, 90],
                ],
                "div-gpt-ad-1701945163318-0"
              )
              .addService(googletag.pubads());
            googletag
              .defineSlot(
                "/1007232/Lallantop_Desktop_ROS_ATF_300x250",
                [300, 250],
                "div-gpt-ad-1701945220410-0"
              )
              .addService(googletag.pubads());
            googletag
              .defineSlot(
                "/1007232/Lallantop_Desktop_ROS_MTF_1_728x90",
                [728, 90],
                "div-gpt-ad-1701945220410-1"
              )
              .addService(googletag.pubads());
            googletag
              .defineSlot(
                "/1007232/Lallantop_Desktop_ROS_MTF_2_728x90",
                [728, 90],
                "div-gpt-ad-1701945220410-2"
              )
              .addService(googletag.pubads());
            googletag
              .defineSlot(
                "/1007232/Lallantop_Desktop_ROS_MTF_3_728x90",
                [728, 90],
                "div-gpt-ad-1701945220410-3"
              )
              .addService(googletag.pubads());
            // googletag.defineSlot('/1007232/Lallantop_Desktop_ROS_BTF_300x250', [[300, 250], [300, 600]], 'div-gpt-ad-1701945864013-0').addService(googletag.pubads());
            // googletag.defineOutOfPageSlot('/1007232/Lallantop_Mobile_HP_Mweb_Interstitial',googletag.enums.OutOfPageFormat.INTERSTITIAL).addService(googletag.pubads());
            // var SECONDS_TO_WAIT_AFTER_VIEWABILITY = 30;
            // googletag
            //   .pubads()
            //   .addEventListener("impressionViewable", function (event) {
            //     var slot = event.slot;
            //     if (
            //       slot.getTargeting(REFRESH_KEY).indexOf(REFRESH_VALUE) > -1
            //     ) {
            //       setTimeout(function () {
            //         googletag.pubads().refresh([slot]);
            //       }, SECONDS_TO_WAIT_AFTER_VIEWABILITY * 1000);
            //     }
            //   });
            googletag.pubads().enableSingleRequest();
            googletag.pubads().setTargeting("Category", "LThomepage");
            googletag
              .pubads()
              .setTargeting("Keywords", [
                "होम",
                "होम लल्लनटॉप",
                "Latest Hindi News",
                "Breaking Hindi News Live",
                "Today Hindi News and Headlines",
              ]);
            googletag.enableServices();
            googletag.pubads().enableSingleRequest();
            googletag.pubads().disableInitialLoad();
            googletag.enableServices();
            requestBiddingFromPWT();
          });
        } else {
          googletag.cmd.push(function () {
            googletag
              .defineSlot(
                "/1007232/Lallantop_Desktop_HP_MTF_300x250",
                [300, 250],
                "div-gpt-ad-1726480392574-0"
              )
              .addService(googletag.pubads());
            googletag
              .defineSlot(
                "/1007232/Lallantop_Desktop_HP_ATF_728x90",
                [
                  [728, 90],
                  [970, 250],
                  [970, 90],
                ],
                "div-gpt-ad-1701945163318-0"
              )
              .addService(googletag.pubads());
            googletag
              .defineSlot(
                "/1007232/Lallantop_Desktop_HP_ATF_300x250",
                [300, 250],
                "div-gpt-ad-1701945220410-0"
              )
              .addService(googletag.pubads());
            googletag
              .defineSlot(
                "/1007232/Lallantop_Desktop_HP_MTF_1_728x90",
                [
                  [728, 90],
                  [970, 250],
                  [970, 90],
                ],
                "div-gpt-ad-1701945220410-1"
              )
              .addService(googletag.pubads());
            googletag
              .defineSlot(
                "/1007232/Lallantop_Desktop_HP_MTF_2_728x90",
                [
                  [728, 90],
                  [970, 250],
                  [970, 90],
                ],
                "div-gpt-ad-1701945220410-2"
              )
              .addService(googletag.pubads());
            googletag
              .defineSlot(
                "/1007232/Lallantop_Desktop_HP_MTF_3_728x90",
                [
                  [728, 90],
                  [970, 90],
                ],
                "div-gpt-ad-1701945220410-4"
              )
              .addService(googletag.pubads());
            googletag
              .defineSlot(
                "/1007232/Lallantop_Desktop_HP_BTF_300x250",
                [300, 250],
                "div-gpt-ad-1701945220410-3"
              )
              .addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.pubads().setTargeting("Category", "LThomepage");
            googletag
              .pubads()
              .setTargeting("Keywords", [
                "होम",
                "होम लल्लनटॉप",
                "Latest Hindi News",
                "Breaking Hindi News Live",
                "Today Hindi News and Headlines",
              ]);
            googletag.enableServices();
            googletag.pubads().enableSingleRequest();
            googletag.pubads().disableInitialLoad();
            googletag.enableServices();
            requestBiddingFromPWT();
          });
        }
      } else {
        if (
          router.pathname.includes("/text") ||
          router.pathname.includes("/video")
        ) {
          googletag.cmd.push(function () {
            googletag
              .defineSlot(
                "/1007232/Lallantop_Mobile_ROS_ATF_300x250",
                [
                  [336, 280],
                  [300, 250],
                ],
                "div-gpt-ad-1701946028239-0"
              )
              .addService(googletag.pubads());
            googletag
              .defineSlot(
                "/1007232/Lallantop_Mobile_ROS_MTF_1_300x250",
                [
                  [336, 280],
                  [300, 250],
                ],
                "div-gpt-ad-1701946028239-1"
              )
              .addService(googletag.pubads());
            googletag
              .defineSlot(
                "/1007232/Lallantop_Mobile_ROS_MTF_2_300x250",
                [
                  [336, 280],
                  [300, 250],
                ],
                "div-gpt-ad-1701946028239-2"
              )
              .addService(googletag.pubads());
            googletag
              .defineSlot(
                "/1007232/Lallantop_Mobile_ROS_Bottom_320x50",
                [320, 50],
                "div-gpt-ad-1701946324333-0"
              )
              .addService(googletag.pubads());
            googletag
              .defineOutOfPageSlot(
                "/1007232/Lallantop_Mobile_ROS_Mweb_Interstitial",
                googletag.enums.OutOfPageFormat.INTERSTITIAL
              )
              .addService(googletag.pubads());
            // var SECONDS_TO_WAIT_AFTER_VIEWABILITY = 30;
            // googletag
            //   .pubads()
            //   .addEventListener("impressionViewable", function (event) {
            //     var slot = event.slot;
            //     if (
            //       slot.getTargeting(REFRESH_KEY).indexOf(REFRESH_VALUE) > -1
            //     ) {
            //       setTimeout(function () {
            //         googletag.pubads().refresh([slot]);
            //       }, SECONDS_TO_WAIT_AFTER_VIEWABILITY * 1000);
            //     }
            //   });
            googletag.pubads().enableSingleRequest();
            googletag.pubads().setTargeting("Category", "LThomepage");
            googletag
              .pubads()
              .setTargeting("Keywords", [
                "होम",
                "होम लल्लनटॉप",
                "Latest Hindi News",
                "Breaking Hindi News Live",
                "Today Hindi News and Headlines",
              ]);
            googletag.enableServices();
            googletag.pubads().enableSingleRequest();
            googletag.pubads().disableInitialLoad();
            googletag.enableServices();
            requestBiddingFromPWT();
          });
        } else {
          googletag.cmd.push(function () {
            var REFRESH_KEY = "refresh";
            var REFRESH_VALUE = "true";
            googletag
              .defineSlot(
                "/1007232/Lallantop_Desktop_HP_MTF_300x250",
                [300, 250],
                "div-gpt-ad-1726480392574-0"
              )
              .addService(googletag.pubads());
            googletag
              .defineSlot(
                "/1007232/Lallantop_Mobile_HP_ATF_300x250",
                [
                  [336, 280],
                  [300, 250],
                ],
                "div-gpt-ad-1701946028239-0"
              )
              .addService(googletag.pubads());
            googletag
              .defineSlot(
                "/1007232/Lallantop_Mobile_HP_MTF_1_300x250",
                [
                  [336, 280],
                  [300, 250],
                ],
                "div-gpt-ad-1701946028239-1"
              )
              .addService(googletag.pubads());
            googletag
              .defineSlot(
                "/1007232/Lallantop_Mobile_HP_MTF_2_300x250",
                [
                  [336, 280],
                  [300, 250],
                ],
                "div-gpt-ad-1701946028239-2"
              )
              .addService(googletag.pubads());
            googletag
              .defineSlot(
                "/1007232/Lallantop_Mobile_HP_BTF_300x250",
                [
                  [336, 280],
                  [300, 250],
                ],
                "div-gpt-ad-1701946028239-3"
              )
              .addService(googletag.pubads());
            googletag
              .defineSlot(
                "/1007232/Lallantop_Mobile_HP_Bottom_320x50",
                [320, 50],
                "div-gpt-ad-1701946324333-0"
              )
              .addService(googletag.pubads());
            googletag
              .defineOutOfPageSlot(
                "/1007232/Lallantop_Mobile_HP_Mweb_Interstitial",
                googletag.enums.OutOfPageFormat.INTERSTITIAL
              )
              .addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.pubads().setTargeting("Category", "LThomepage");
            googletag
              .pubads()
              .setTargeting("Keywords", [
                "होम",
                "होम लल्लनटॉप",
                "Latest Hindi News",
                "Breaking Hindi News Live",
                "Today Hindi News and Headlines",
              ]);
            googletag.enableServices();
            googletag.pubads().enableSingleRequest();
            googletag.pubads().disableInitialLoad();
            googletag.enableServices();
            requestBiddingFromPWT();
          });
        }
      }
      // apstag.fetchBids(
      //   {
      //     slots:
      //       size?.width < 499
      //         ? createApsSlotsRosMobile({})
      //         : createApsSlotsRosDesktop({}),
      //     timeout: 2e3,
      //   },
      //   apstagBidCallback
      // );
    }, 500);
    // if (showAds && !adCalled) {
    //   setAdCalled(true);
    //   console.log("adscalled");

    // }
  }, []);

  useEffect(() => {
    async function onFirstScroll() {
      setFirstScroll(true);

      window._taboola = window._taboola || [];
      _taboola.push({ homepage: "auto" });
      !(function (e, f, u, i) {
        if (!document.getElementById(i)) {
          e.async = 1;
          e.src = u;
          e.id = i;
          f.parentNode.insertBefore(e, f);
        }
      })(
        document.createElement("script"),
        document.getElementsByTagName("script")[0],
        "//cdn.taboola.com/libtrc/indiatoday-thelallantop/loader.js",
        "tb_loader_script"
      );
      if (window.performance && typeof window.performance.mark === "function") {
        window.performance.mark("tbl_ic");
      }

      window._taboola = window._taboola || [];
      _taboola.push({
        mode: "alternating-thumbnails-a",
        container: "taboola-below-homepage-thumbnails",
        placement: "Below Homepage Thumbnails",
        target_type: "mix",
      });
    }

    function handleScroll() {
      onFirstScroll();
      window.removeEventListener("scroll", handleScroll);
    }

    window.addEventListener("scroll", handleScroll);
  }, []);

  const ItemListSchema = () => {
    var itemArray = [];
    let keyValueArray = [];
    var arrayforSchema;
    if (router?.asPath === "/") {
      arrayforSchema = featuredPosts;
    } else {
      arrayforSchema = NewsPageData?.entities;
    }
    console.log("arrayforSchema", arrayforSchema);
    for (let i = 1; i <= 10; i++) {
      let position = {};
      let typeKey = "@type";
      let typeValue = "ListItem";
      position[typeKey] = typeValue;
      // position.push({ [typeKey]: typeValue });
      typeKey = "position";
      typeValue = i;
      position[typeKey] = typeValue;
      typeKey = "name";
      typeValue = arrayforSchema[i - 1]?.title;
      // position.push({ [typeKey]: typeValue });
      position[typeKey] = typeValue;

      // typeKey = "description";
      // typeValue = featuredPosts[i - 1]?.shortText;
      // position[typeKey] = typeValue;
      typeKey = "url";
      typeValue = arrayforSchema[i - 1]?.permalink;
      position[typeKey] = typeValue;
      keyValueArray.push(position);
    }
    itemArray = keyValueArray;
    console.log("ItemListSchema", featuredPosts);

    // for (let x = 1; x <= 10; x++) {
    //   var item = `"@type": "ListItem","name": ${
    //     featuredPosts[x]?.title
    //   },"position": ${x + 1},"url":${featuredPosts[x]?.permalink},`;
    //   itemArray.push(item);
    // }
    // var itemSchema = `{"@context": "https://schema.org","@type": "ItemList","itemListElement": ${itemArray}}`;
    // console.log("ItemListSchema 1", itemSchema);

    // itemSchema = itemSchema.replace(/\\/g, "");
    return itemArray;
  };
  console.log("CHUNVA DAATA ", homePageVideosData);
  return (
    <>
      <Head>
        <meta property="fb:pages" content="1453842511589254" />
        <meta
          name="keywords"
          content={`${
            router.pathname === "/"
              ? "Hindi News, Hindi Samachar, Breaking News in Hindi, Today Headlines, Latest News in Hindi, हिंदी समाचार, हिंदी न्यूज़, ताजा ख़बरें"
              : router.pathname === "/text"
              ? "Hindi news Article, news story in hindi, breaking news article in hindi, latest news article in hindi, latest hindi news Stories, today news story in hindi, hindi news story today, हिंदी न्यूज़ पढ़िए, हिन्दी समाचार पढ़िए, पढ़िए हिंदी में समाचार"
              : router.pathname === "/video"
              ? "Hindi news Video, Video story in hindi, breaking news Video in hindi, latest news Video in hindi, latest hindi news Video, watch today news story in hindi, hindi news today, हिंदी न्यूज़ देखिये, हिन्दी समाचार देखिये, देखिये हिंदी में समाचार, Trending Video, Viral Video today"
              : "Hindi news Audio, audio story in hindi, breaking news podcast in hindi, latest news podcast in hindi, latest hindi news audio stories, Listen today news story in hindi, hindi news today, हिंदी न्यूज़ सुनिए, हिन्दी समाचार सुनिए, सुनिए हिंदी में समाचार, Trending audio stories, Viral podcast today"
          }`}
        />
        {/* <link rel="amphtml" href="https://m.thelallantop.com/" /> */}
        <link
          rel="preconnect"
          crossOrigin="true"
          href="https://static.chartbeat.com"
        ></link>
        <link
          rel="preconnect"
          crossOrigin="true"
          href="https://www.gstatic.com"
        ></link>
        <link
          rel="preconnect"
          crossOrigin="true"
          href="https://f.vimeocdn.com"
        ></link>
      </Head>
      <NextSeo
        title={
          router.pathname === "/"
            ? "Hindi News, ताज़ा हिंदी समाचार | Breaking News Headlines Today in Hindi | Latest News Today Hindi | हिंदी न्यूज़ | The Lallantop"
            : router.pathname === "/text"
            ? "Hindi News Article, हिंदी न्यूज़ पढ़िए , Today Breaking News Article In Hindi, Today News Headlines"
            : router.pathname === "/video"
            ? "Hindi News Video, Trending Video हिंदी न्यूज़ , Today Breaking News Video, Today News Video"
            : "Hindi News Podcast, Trending podcast हिंदी न्यूज़ , Today Breaking News सुनिए, Today News Podcast"
        }
        canonical={
          `https://www.thelallantop.com` +
          (router.asPath.includes("/text")
            ? "/text"
            : router.asPath.includes("/video")
            ? "/video"
            : "")
        }
        description={
          router.pathname === "/"
            ? "Hindi News (हिंदी न्यूज़) - Read here देश, विदेश, खेल, राजनीति, क्राइम, ऑटोमोबाइल, टेक्नोलॉजी, शिक्षा, लाइफस्टाइल, क्रिकेट से सम्बंधित आज की मुख्य खबरें, ताज़ा हिंदी समाचार (Latest Hindi News), ब्रेकिंग न्यूज़ (Breaking News) और लेटेस्ट न्यूज़ (Latest News in Hindi) हिंदी में on The Lallantop."
            : router.pathname === "/text"
            ? "Hindi News Article (हिंदी न्यूज़) - पढ़िए देश, विदेश, खेल, राजनीति, क्राइम, ऑटोमोबाइल, टेक्नोलॉजी, शिक्षा, लाइफस्टाइल, क्रिकेट और ज्योतिष से सम्बंधित सभी समाचार हिंदी में (News Stories In Hindi) Lallantop पर.  Read Breaking News Headlines in Hindi on lallantop Website."
            : router.pathname === "/video"
            ? "Hindi News Viral Video (हिंदी न्यूज़) - Watch देश, विदेश, खेल, राजनीति, क्राइम, ऑटोमोबाइल, टेक्नोलॉजी, शिक्षा, लाइफस्टाइल, क्रिकेट और ज्योतिष से सम्बंधित सभी समाचार हिंदी में (Video Stories In Hindi) Lallantop पर.  Watch Trending News Video in Hindi on lallantop Website."
            : " Hindi News Viral Podcast (हिंदी न्यूज़) - listen देश, विदेश, खेल, राजनीति, क्राइम, ऑटोमोबाइल, टेक्नोलॉजी, शिक्षा, लाइफस्टाइल, क्रिकेट और ज्योतिष से सम्बंधित सभी समाचार हिंदी में (Podcast Stories In Hindi) Lallantop पर.  Watch Trending News PODCAST in Hindi on lallantop Website."
        }
      />
      {/* <h1 style={{display:"none"}}>Lallantop Hindi News</h1> */}
      {firstScroll && (
        <Script
          id="taboola"
          //strategy="afterInteractive"
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
              window._taboola = window._taboola || [];
              _taboola.push({homepage:'auto'});
              !function (e, f, u, i) {
              if (!document.getElementById(i)){
              e.async = 1;
              e.src = u;
              e.id = i;
              f.parentNode.insertBefore(e, f);
              }
              }(document.createElement('script'),
              document.getElementsByTagName('script')[0],
              '//cdn.taboola.com/libtrc/indiatoday-thelallantop/loader.js',
              'tb_loader_script');
              if(window.performance && typeof window.performance.mark == 'function')
              {window.performance.mark('tbl_ic');}
              `,
          }}
        ></Script>
      )}
      {/* {size.width > 600 && (
        <GoogleAdContainer divId="div-gpt-ad-1686738608479-0" /> */}
      <div
        className="container-row py-2 text-center deaktop-header-ad"
        style={{
          overflow: "hidden",
        }}
      >
        <p
          style={{
            fontSize: "11px",
            color: "#b7b7b7",
            marginBottom: "0px",
          }}
        >
          Advertisement
        </p>

        {size.width > 600 && (
          <div
            id={"div-gpt-ad-1701945163318-0"}
            style={{
              minHeight: "100px",
              alignContent: "center",
              textAlign: "-webkit-center",
            }}
          >
            <script
              type="text/javascript"
              defer
              dangerouslySetInnerHTML={{
                __html: `{googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701945163318-0')})`,
              }}
            ></script>
          </div>
        )}
      </div>
      {/* {masterConfig?.vidhansabha_election?.HRExittally && ( */}
      {masterObj.MaharashtraExittally && (
        <>
          {size.width < 768 ? (
            <div className="result-page">
              <h2
                style={{
                  fontSize: "27px",
                  color: "#D9368B",
                  fontWeight: "800",
                }}
              >
                विधानसभा एग्ज़िट पोल
              </h2>
              <div>
                <iframe
                  src={`${masterObj.MaharashtraExittally_URL}`}
                  width="100%"
                  height={masterObj.Maharashtra_Exitpolltally_height_mobile}
                ></iframe>
              </div>
            </div>
          ) : (
            <div
              style={{
                backgroundColor: "white",
              }}
            >
              <div className="result-page main-container-featured1">
                <h2
                  style={
                    size.width >= 800 && size.width <= 1000
                      ? {
                          fontSize: "27px",
                          color: "#D9368B",
                          fontWeight: "800",
                          marginTop: "45px",
                        }
                      : {
                          fontSize: "27px",
                          color: "#D9368B",
                          fontWeight: "800",
                        }
                  }
                >
                  विधानसभा एग्ज़िट पोल
                </h2>
                <div>
                  <iframe
                    src={`${masterObj.MaharashtraExittally_URL}`}
                    width="100%"
                    height={masterObj.Maharashtra_Exitpolltally_height_web}
                  ></iframe>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {masterObj.MaharashtraResulttally && (
        <>
          {size.width < 768 ? (
            <div className="result-page-big">
              <h2
                style={{
                  fontSize: "27px",
                  color: "#D9368B",
                  fontWeight: "800",
                }}
              >
                विधानसभा रिजल्ट
              </h2>
              <div>
                <iframe
                  src={masterObj.MaharashtraResulttally_URL}
                  width="100%"
                  height={masterObj.Maharashtra_Resulttally_height_mobile}
                ></iframe>
              </div>
            </div>
          ) : (
            <div
              style={{
                backgroundColor: "white",
              }}
            >
              <div className="result-page-big main-container-featured1">
                <h2
                  style={{
                    fontSize: "27px",
                    color: "#D9368B",
                    fontWeight: "800",
                  }}
                >
                  विधानसभा रिजल्ट
                </h2>
                <div>
                  <iframe
                    src={masterObj.MaharashtraResulttally_URL}
                    width="100%"
                    height={
                      size.width != 820 && size.width <= 1000
                        ? masterObj.Maharashtra_Resulttally_height_tab
                        : masterObj.Maharashtra_Resulttally_height_web
                    }
                  ></iframe>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {masterObj.BypollResulttally && (
        <>
          {size.width < 768 ? (
            <div className="result-page-big">
              <h2
                style={{
                  fontSize: "27px",
                  color: "#D9368B",
                  fontWeight: "800",
                }}
              >
                उपचुनाव रिजल्ट
              </h2>
              <div>
                <iframe
                  src={masterObj.BypollResulttally_URL}
                  width="100%"
                  height={masterObj.Bypoll_Resulttally_height_mobile}
                ></iframe>
              </div>
            </div>
          ) : (
            <div className="result-page-big">
              <h2
                style={{
                  fontSize: "27px",
                  color: "#D9368B",
                  fontWeight: "800",
                }}
              >
                उपचुनाव रिजल्ट
              </h2>
              <div>
                <iframe
                  src={masterObj.BypollResulttally_URL}
                  width="100%"
                  height={masterObj.Bypoll_Resulttally_height_web}
                ></iframe>
              </div>
            </div>
          )}
        </>
      )}
      <br></br>
      {/* CHUNAV STRIP START  */}
      {/* {
       

       (size.width < 768) ? 
       (
         <>
           <div>
            {console.log('abccccccccc')}
             <div
               style={{
                 display: "flex",
                 justifyContent: "center",
                 paddingTop: "1rem",
                 gap: "8px",
                 // cursor: "pointer",
                 paddingBottom: "1rem",
                 backgroundColor: "white",
                 marginTop: "10px",
                 
               }}
           
             >
               <img
                 src="/assets/images/assembly-chunav.png"
                 width="240px"
                 height="auto"
                 style={{ alignSelf: "center" }}
               ></img>
             </div>
             <div
               class="divCategoriesSchedule2 snipcss-eqdpv"
               style={{
                 margin: "-1px 0px",
                 backgroundColor: "white",
                 paddingLeft: "15px",
                 paddingBottom: "1rem",
                 justifyContent:
                   router.asPath.includes("jharkhand-") ||
                   router.asPath.includes("maharashtra-")
                     ? "center"
                     : "space-around",
               }}
             >
              <br></br>
               { STATIC_BREADCRUMP.map((item, index) => {
                     return masterConfig[item.keyToShow] != false ? (
                       <a key={index} href={item.url}>
                         <span>{item.displayText}</span>
                       </a>
                     ) : null;
                   })}
              
             </div>
           </div>
         </>
       ) : 
       (
        
        <>
        {size?.width >=768 &&   <div
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "white",
              width: "max-content",
              margin: "auto",
              gap: "68px",
              height: "80px",
              marginTop: "15px",
              marginBottom: "15px",
              borderRadius: "12px",
              padding: "0px 30px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                // paddingTop: "1rem",
                gap: "8px",
                // cursor: "pointer",
                backgroundColor: "white",
                paddingLeft: "0px",
              }}
          
            >
              
              <img
                src="/assets/images/assembly-chunav.png"
                width="408.56px"
                height="58.21px"
                style={{ alignSelf: "center" }}
              ></img>
            </div>
                <br/>
            <div
              class="divCategoriesSchedule2 snipcss-eqdpv"
              style={{
                margin: "0px 0px",
                // backgroundColor: "rgb(217, 54, 139)",
                paddingLeft: "15px",
                alignContent: "center",
              }}
            >
              { STATIC_BREADCRUMP.map((item, index) => {
                    return masterConfig[item.keyToShow] != false ? (
                      <a key={index} href={item.url}>
                        <span>{item.displayText}</span>
                      </a>
                    ) : null;
                  })}
             
            </div>
          </div>}
        </>
      )
     } */}
      {/* CHUNAV STRIP END  */}
      {/* )} */}
      {/* <TopPodcastContainer topPodcastData={topPodcastData} />
      <AuthorColumn authorWidgetData={authorWidgetData}></AuthorColumn> */}
      {/* {size.width < 1100 && (
        <>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "1rem",
                gap: "8px",
                cursor: "pointer",
                paddingBottom: "1rem",
                backgroundColor: "rgb(217, 54, 139)",
              }}
              onClick={() =>
                window.open("/election/lok-sabha-chunav-2024", "_self")
              }
            >
        
              <img
                src="/assets/images/chunav-name.png"
                width="197.58px"
                height="45.21px"
                style={{ alignSelf: "center" }}
              ></img>
            </div>
            <div
              class="divCategoriesSchedule snipcss-eqdpv"
              style={{
                margin: "-1px 0px",
                backgroundColor: "rgb(217, 54, 139)",
                paddingLeft: "15px",
                paddingBottom: "1rem",
              }}
            >
              <a href="/election/lok-sabha-chunav-2024">
                <span>न्यूज़</span>
              </a>{" "}
              <a href="/election/lok-sabha-chunav-2024/results">
                <span>कौन जीता-कौन हारा</span>
              </a>{" "}
              <a href="/election/lok-sabha-chunav-2024/party-wise-result">
                <span>लोक सभा रिजल्ट</span>
              </a>
            
              <a href="/election/lok-sabha-chunav-2024/key-candidates">
                <span>प्रमुख उम्मीदवार</span>
              </a>
          
              <a href="/election/vidhan-sabha-chunav-2024/party-wise-result">
                <span>विधान सभा रिजल्ट</span>
              </a>
             
            </div>
          </div>
          <div style={{ backgroundColor: "rgb(187 10 103)" }}>
            <div
              style={{
                fontSize: "12px",
                fontWeight: "700",
                color: "white",
                paddingTop: "10px",
                textAlign: "center",
              }}
            >
              POWERED BY
            </div>
            <div
              style={{
                display: "flex",
                gap: "30px",
                justifyContent: "center",
                padding: "10px",
              }}
            >
              <img
                width="80px"
                height="60px"
                style={{ backgroundColor: "white", padding: "7px" }}
                src="https://akm-img-a-in.tosshub.com/lingo/uptak/resources/mangaldeep-logo-1.png"
              ></img>
              <img
                width="90px"
                height="60px"
                style={{ backgroundColor: "white", padding: "7px" }}
                src="https://akm-img-a-in.tosshub.com/lingo/uptak/resources/sensodent-k-1.png"
              ></img>
              <img
                width="90px"
                height="60px"
                style={{ backgroundColor: "white", padding: "7px" }}
                src="https://static.thelallantop.com/images/post/1717414469873_sunrise_logo_2nd_april_final.webp"
              ></img>
            </div>
          </div>
        </>
      )} */}
      {/* {size.width >= 1100 && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#D9368B",
              width: "85%",
              margin: "auto",
              gap: "68px",
              height: "80px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                // paddingTop: "1rem",
                gap: "8px",
                cursor: "pointer",
                backgroundColor: "rgb(217, 54, 139)",
                paddingLeft: "20px",
              }}
              onClick={() =>
                window.open("/election/lok-sabha-chunav-2024", "_self")
              }
            >
              <img
                src="/assets/images/chunav-name.png"
                width="197.56px"
                height="45.21px"
                style={{ alignSelf: "center" }}
              ></img>
            </div>
            <div
              class="divCategoriesSchedule snipcss-eqdpv"
              style={{
                margin: "0px 0px",
                backgroundColor: "rgb(217, 54, 139)",
                paddingLeft: "15px",
                alignContent: "center",
              }}
            >
              <a href="/election/lok-sabha-chunav-2024">
                <span>न्यूज़</span>
              </a>{" "}
              <a href="/election/lok-sabha-chunav-2024/results">
                <span>कौन जीता-कौन हारा</span>
              </a>{" "}
              <a href="/election/lok-sabha-chunav-2024/party-wise-result">
                <span>लोक सभा रिजल्ट</span>
              </a>
         
              <a href="/election/lok-sabha-chunav-2024/key-candidates">
                <span>प्रमुख उम्मीदवार</span>
              </a>
              
              <a href="/election/vidhan-sabha-chunav-2024/party-wise-result">
                <span>विधान सभा रिजल्ट</span>
              </a>
            
            </div>
          </div>
          <div
            style={{
              backgroundColor: "rgb(187 10 103)",
              width: "85%",
              margin: "auto",
            }}
          >
            <div
              style={{
                fontSize: "12px",
                fontWeight: "700",
                color: "white",
                paddingTop: "10px",
                textAlign: "center",
              }}
            >
              POWERED BY
            </div>
            <div
              style={{
                display: "flex",
                gap: "30px",
                justifyContent: "center",
                padding: "10px",
              }}
            >
              <img
                width="80px"
                height="60px"
                style={{ backgroundColor: "white", padding: "7px" }}
                src="https://akm-img-a-in.tosshub.com/lingo/uptak/resources/mangaldeep-logo-1.png"
              ></img>
              <img
                width="90px"
                height="60px"
                style={{ backgroundColor: "white", padding: "7px" }}
                src="https://akm-img-a-in.tosshub.com/lingo/uptak/resources/sensodent-k-1.png"
              ></img>
              <img
                width="90px"
                height="60px"
                style={{ backgroundColor: "white", padding: "7px" }}
                src="https://static.thelallantop.com/images/post/1717414469873_sunrise_logo_2nd_april_final.webp"
              ></img>
            </div>
          </div>
        </>
      )} */}
      {/* {size?.width < 600 && liveShowData?.totalCount > 0 && (
        <div className="live-mobile" style={{ marginTop: "20px" }}>
          <LiveShow />
        </div>
      )} */}
      <ElectionsWidget />
      {/* <div className="iframe-container">
        <div className="">
        <iframe className="responsive-iframe" scrolling="no"  src="https://electionresults.intoday.in/elections/2023/assembly-elections/html/december/exit-poll/exit-poll-tak.html?site=lallantop&v=2.1" width="100%" height="100%"></iframe>
        </div>
      </div> */}
      {/* <div
        className="checkMobileOrNot election-banner"
        onClick={() => router.push("https://www.thelallantop.com/election")}
        style={{
          minHeight: "90px",
          // marginTop: "5px",
          cursor: "pointer",
          // border: "1px solid black;",
        }}
      >
        <img
          // className="one-px-solid-br"
          style={{ minWidth: "100%" }}
          src={`https://static.thelallantop.com/images/post/1701653410443_774x172_copy_(1).webp?${
            !firstScroll ? "width=300" : ""
          }`}
          alt="adda-banner"
        />
      </div>
      <div className="iframe-container2">
        <div
          className="checkDesktopOrNot election-banner"
          onClick={() =>
            router.push("https://www.thelallantop.com/election")
          }
          style={{
            width: "88%",
            minHeight: "66px",
            marginBottom: "-10px",
            paddingTop: "10px",

            cursor: "pointer",
            // border: "1px solid black;",
          }}
        >
          <img
            // className="one-px-solid-br"
            style={{ minWidth: "100%" }}
            src={`https://static.thelallantop.com/images/post/1701653398038_1270x90_copy_(1).webp?${
              !firstScroll ? "width=300" : ""
            }`}
            alt="adda-banner"
          />
        </div>
        <div className="">
          <iframe
            className="responsive-iframe2"
            src="https://elections.mobiletak.com/tally/all?theme=llt"
            width="100%"
            height="450px"
          ></iframe>
        </div>
      </div> */}
      <MainContainer
        featuredPosts={featuredPosts}
        bannerData={bannerData}
        liveShowData={liveShowData}
        // cricketResponse={cricketResponse}
        quizPage={quizHomeData}
        firstScroll={firstScroll}
      />
      <div style={{ width: "100%", background: "#016369", marginTop: "8px" }}>
        {router.pathname === "/" && (
          <div className="electionYatra-outer-home">
            <div className="electionYatra-header">
              <div>
                <img
                  src={homePageVideosData?.icon}
                  width="35px"
                  height="35px"
                  alt="election-icon"
                ></img>
                <span style={{ marginLeft: "10px" }}>
                  {language === "hi" ? "लेटेस्ट वीडियो" : "Latest Videos"}
                </span>
              </div>
              <a
                style={{ textDecoration: "none", color: "white" }}
                href={homePageVideosData?.permalink}
              >
                <div
                  className="election-aurDekhe"
                  // onClick={() => router.push(homePageVideosData?.permalink)}
                >
                  <span style={{ marginRight: "10px" }}>
                    <FormattedMessage id="see_more" />
                  </span>
                  <img
                    src="/assets/images/arrow.png"
                    width="10px"
                    height="10px"
                  ></img>
                </div>
              </a>
            </div>
            <div className="electionYatra-columns">
              {
                <a
                  className="checkMobileOrNot"
                  href={`${
                    homePageVideosData?.entities &&
                    homePageVideosData?.entities?.length > 0 &&
                    homePageVideosData?.entities[0]?.permalink
                  }`}
                >
                  <div className="electionYatra-second election-mobile">
                    <div
                      className="electionYatra-second-top"
                      style={{ position: "relative" }}
                    >
                      <img
                        style={{
                          width: "100%",
                          // maxHeight: "56.2%",
                          // height: "209.2px",
                        }}
                        src={
                          homePageVideosData?.entities &&
                          homePageVideosData?.entities?.length > 0 &&
                          homePageVideosData?.entities[0]?.headingImageUrl
                        }
                      ></img>
                      <i
                        className="icon-play-circle"
                        width="500px"
                        height="500px"
                        style={{
                          color: "white",
                          position: "absolute",
                          left: "2px",
                          bottom: "-5px",
                          width: "500px",
                        }}
                      ></i>
                    </div>
                    <div className="electionYatra-second-bottom">
                      <h2>
                        {homePageVideosData?.entities &&
                          homePageVideosData?.entities?.length > 0 &&
                          homePageVideosData?.entities[0]?.title}
                      </h2>
                      {/* <h4>{electionTopicData[0]?.shortText}</h4> */}
                    </div>
                  </div>
                </a>
              }

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                {homePageVideosData?.entities &&
                  homePageVideosData?.entities?.length > 0 &&
                  homePageVideosData.entities.slice(1).map((x, index) => {
                    return (
                      <a href={`${x?.permalink}`} key={index}>
                        <div className="electionYatra-first">
                          <div className="electionYatra-first-div">
                            <div
                              style={{
                                position: "relative",
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={x?.headingImageUrl}
                                style={{
                                  width: "134px",
                                  // maxHeight: "56.2%",
                                  height: "75.7px",
                                }}
                              ></img>
                              <i
                                className="icon-play-circle"
                                style={{
                                  color: "white",
                                  position: "absolute",
                                  left: "2px",
                                  bottom: "-5px",
                                }}
                              ></i>
                            </div>
                            <div
                              className="electionYatra-first-div-right"
                              style={{ flex: "0 0 60%" }}
                            >
                              <div>
                                <div
                                  style={{
                                    overflow: "hidden",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "-webkit-box",
                                      lineHeight: "22px",
                                      WebkitLineClamp: "3",
                                      WebkitBoxOrient: "vertical",
                                      marginTop: "5px",
                                    }}
                                    className="chunav-yatra-small-title"
                                  >
                                    {x?.title}
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "1rem",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </a>
                    );
                  })}
              </div>

              {
                <a
                  style={{ width: "fit-content" }}
                  className="checkDesktopOrNot"
                  href={`${
                    homePageVideosData?.entities &&
                    homePageVideosData?.entities?.length > 0 &&
                    homePageVideosData.entities[0]?.permalink
                  }`}
                >
                  <div className="electionYatra-second election-desktop">
                    <div
                      className="electionYatra-second-top"
                      style={{ position: "relative" }}
                    >
                      <img
                        style={{
                          width: "743px",
                          // maxHeight: "56.2%",
                          // height: "420.2px",
                        }}
                        src={
                          homePageVideosData?.entities &&
                          homePageVideosData?.entities?.length > 0 &&
                          homePageVideosData.entities[0]?.headingImageUrl
                        }
                      ></img>
                      <i
                        className="icon-play-circle"
                        width="500px"
                        height="500px"
                        style={{
                          color: "white",
                          position: "absolute",
                          left: "2px",
                          bottom: "-5px",
                          width: "500px",
                        }}
                      ></i>
                    </div>
                    <div className="electionYatra-second-bottom">
                      <h2>
                        {homePageVideosData?.entities &&
                          homePageVideosData?.entities?.length > 0 &&
                          homePageVideosData.entities[0]?.title}
                      </h2>
                      <h4>
                        {homePageVideosData?.entities &&
                          homePageVideosData?.entities?.length > 0 &&
                          homePageVideosData.entities[0]?.shortText}
                      </h4>
                    </div>
                  </div>
                </a>
              }
            </div>
          </div>
        )}
      </div>
      <div className="container whats">
        <a
          className="whatsappChannel"
          href="https://whatsapp.com/channel/0029Va9lDkJDp2QAHxZogJ2r"
          target="_blank"
          rel="noreferrer"
        >
          लल्लनटॉप का
          <span className="greenW">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="76.001"
              height="76"
              viewBox="0 0 76.001 76"
            >
              <g
                data-name="Group 50357"
                transform="translate(-203.999 -353.381)"
              >
                <path
                  id="Path_17532"
                  data-name="Path 17532"
                  d="M38.014,76h0a38.26,38.26,0,0,1-18.066-4.551L3.4,75.881A2.68,2.68,0,0,1,.108,72.594L4.546,56.04A38.258,38.258,0,0,1,.323,32.988,38.022,38.022,0,0,1,23.583,2.84,38,38,0,1,1,43.011,75.671,38.277,38.277,0,0,1,38.014,76ZM24.655,19.749a2.189,2.189,0,0,0-1.556.646,24.919,24.919,0,0,0,0,35.212,2.2,2.2,0,0,0,3.111,0,2.191,2.191,0,0,0,0-3.11,20.518,20.518,0,0,1,0-28.98,2.212,2.212,0,0,0,0-3.123A2.189,2.189,0,0,0,24.655,19.749Zm26.689,0a2.2,2.2,0,0,0-1.556,3.757,20.492,20.492,0,0,1,0,28.98,2.2,2.2,0,0,0,0,3.11,2.209,2.209,0,0,0,3.111,0,24.9,24.9,0,0,0,0-35.2A2.189,2.189,0,0,0,51.345,19.749ZM38.5,32a6,6,0,1,0,6,6A6.008,6.008,0,0,0,38.5,32Z"
                  transform="translate(204 353.381)"
                  fill="#58be55"
                ></path>{" "}
              </g>{" "}
            </svg>
          </span>
          चैनल
          <span class="waJoin">JOIN</span>
          करें
        </a>
      </div>
      {
        <div style={{ display: "none" }}>
          <ListingPage></ListingPage>
        </div>
      }
      {(router.asPath.split("?")[0] === "/" ||
        router.asPath.split("?")[0] === "/video") && (
        <>
          {/* <ShortsSlider shortsData={shortsData} /> */}
          {size.width < 600 && router.asPath.split("?")[0] === "/" && (
            <GoogleAdContainer divId="div-gpt-ad-1701946028239-1" />
          )}
          {!router.pathname.includes("/text") &&
            !router.pathname.includes("/video") && (
              <VisualStories
                responseVs={responseVs?.data?.all}
                liveBlogWidgetData={liveBlogWidgetData}
              />
            )}
          {size.width < 600 && router.asPath.split("?")[0] === "/video" && (
            <GoogleAdContainer divId="div-gpt-ad-1701946028239-0" />
          )}
        </>
      )}
      {/* {router.pathname === "/" && (
        <GoogleAdContainer divId="div-gpt-ad-1686738608479-2" />
      )} */}
      {/* Top Shows Here */}
      {/* {(router.asPath === "/" || router.asPath === "/video") && (
        <TopShows topShowsData={topShowsData} />
      )} */}
      {console.log("THIS IS ARTICLE PAGA ", router.asPath.split("?"))}
      {/* {(router.asPath.split("?")[0] === "/" ||
        router.asPath.split("?")[0] === "/video") && (
        <TopShowsWidget TopShowsWidgetData={topShowsWidgetData} />
      )} */}
      {size.width > 600 &&
        !router.pathname.includes("/text") &&
        !router.pathname.includes("/video") && (
          <GoogleAdContainer divId="div-gpt-ad-1701945220410-1" />
        )}
      {size.width <= 499 &&
        !router.pathname.includes("/text") &&
        !router.pathname.includes("/video") && (
          <BlogWidget liveBlogWidgetData={liveBlogWidgetData} />
        )}
      {size.width <= 499 && !router.pathname.includes("/video") && (
        <div className="container-row py-2" align="center">
          <p
            style={{ fontSize: "11px", color: "#b7b7b7", marginBottom: "0px" }}
          >
            Advertisement
          </p>
          <div
            id={
              size.width > 600
                ? router.asPath.split("?")[0] === "/"
                  ? "div-gpt-ad-1701945220410-2"
                  : router.asPath.split("?")[0] === "/text"
                  ? "div-gpt-ad-1701945220410-1"
                  : "div-gpt-ad-1701945220410-2"
                : router.pathname === "/"
                ? "div-gpt-ad-1701946028239-2"
                : router.asPath.split("?")[0] === "/text"
                ? "div-gpt-ad-1701946028239-0"
                : "div-gpt-ad-1701946028239-1"
            }
            style={{
              minWidth: "300px",
              minHeight: size.width > 600 ? "100px" : "260px",
              display: "block !important",
              alignContent: "center",
              textAlign: "-webkit-center",
            }}
          >
            <script
              type="text/javascript"
              dangerouslySetInnerHTML={{
                __html:
                  size.width > 600
                    ? router.asPath.split("?")[0] === "/"
                      ? `{googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701945220410-2')})`
                      : router.asPath.split("?")[0] === "/text"
                      ? `{googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701945220410-1')})`
                      : `{googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701946028239-1')})`
                    : router.pathname === "/"
                    ? `
              {googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701946028239-2')})
              `
                    : router.asPath.split("?")[0] === "/text"
                    ? `
              {googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701946028239-0')})
              `
                    : `
              {googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701946028239-1')})
              `,
              }}
            ></script>
          </div>
        </div>
      )}
      <CategoryPosts
        shortsData={shortsData}
        NewsPageData={NewsPageData}
        topShowsWidgetData={topShowsWidgetData}
        topPodcastData={topPodcastData}
        authorWidgetData={authorWidgetData}
      />
      {/* <GoogleAdContainer divId="div-gpt-ad-1686738608479-8" /> */}
      <FAQComponent />
      <TaboolaAdContainer />
      <script
        id="website-schema"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(breadcrumbSchema),
        }}
      />
      (
      <script
        id="website-schema-item"
        type="application/ld+json"
        // strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "ItemList",
            itemListElement: ItemListSchema(),
          }),
        }}
      ></script>
      <script
        id="my-script-detail"
        type="application/ld+json"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "http://schema.org/",
            "@type": "WebPage",
            name: `${
              router.pathname === "/"
                ? "Hindi News, ताज़ा हिंदी समाचार | Breaking News Headlines Today in Hindi | Latest News Today Hindi | हिंदी न्यूज़ | The Lallantop"
                : router.pathname === "/text"
                ? "Hindi News Article, हिंदी न्यूज़ पढ़िए , Today Breaking News Article In Hindi, Today News Headlines"
                : router.pathname === "/video"
                ? "Hindi News Video, Trending Video हिंदी न्यूज़ , Today Breaking News Video, Today News Video"
                : "Hindi News Podcast, Trending podcast हिंदी न्यूज़ , Today Breaking News सुनिए, Today News Podcast"
            }`,
            description: `${
              router.pathname === "/"
                ? "Hindi News (हिंदी न्यूज़) - Read here देश, विदेश, खेल, राजनीति, क्राइम, ऑटोमोबाइल, टेक्नोलॉजी, शिक्षा, लाइफस्टाइल, क्रिकेट से सम्बंधित आज की मुख्य खबरें, ताज़ा हिंदी समाचार (Latest Hindi News), ब्रेकिंग न्यूज़ (Breaking News) और लेटेस्ट न्यूज़ (Latest News in Hindi) हिंदी में on The Lallantop."
                : router.pathname === "/text"
                ? "Hindi News Article (हिंदी न्यूज़) - पढ़िए देश, विदेश, खेल, राजनीति, क्राइम, ऑटोमोबाइल, टेक्नोलॉजी, शिक्षा, लाइफस्टाइल, क्रिकेट और ज्योतिष से सम्बंधित सभी समाचार हिंदी में (News Stories In Hindi) Lallantop पर.  Read Breaking News Headlines in Hindi on lallantop Website."
                : router.pathname === "/video"
                ? "Hindi News Viral Video (हिंदी न्यूज़) - Watch देश, विदेश, खेल, राजनीति, क्राइम, ऑटोमोबाइल, टेक्नोलॉजी, शिक्षा, लाइफस्टाइल, क्रिकेट और ज्योतिष से सम्बंधित सभी समाचार हिंदी में (Video Stories In Hindi) Lallantop पर.  Watch Trending News Video in Hindi on lallantop Website."
                : " Hindi News Viral Podcast (हिंदी न्यूज़) - listen देश, विदेश, खेल, राजनीति, क्राइम, ऑटोमोबाइल, टेक्नोलॉजी, शिक्षा, लाइफस्टाइल, क्रिकेट और ज्योतिष से सम्बंधित सभी समाचार हिंदी में (Podcast Stories In Hindi) Lallantop पर.  Watch Trending News PODCAST in Hindi on lallantop Website."
            }`,
            keywords: `${
              router.pathname === "/"
                ? "Hindi News, Hindi Samachar, Breaking News in Hindi, Today Headlines, Latest News in Hindi, हिंदी समाचार, हिंदी न्यूज़, ताजा ख़बरें"
                : router.pathname === "/text"
                ? "Hindi news Article, news story in hindi, breaking news article in hindi, latest news article in hindi, latest hindi news Stories, today news story in hindi, hindi news story today, हिंदी न्यूज़ पढ़िए, हिन्दी समाचार पढ़िए, पढ़िए हिंदी में समाचार"
                : router.pathname === "/video"
                ? "Hindi news Video, Video story in hindi, breaking news Video in hindi, latest news Video in hindi, latest hindi news Video, watch today news story in hindi, hindi news today, हिंदी न्यूज़ देखिये, हिन्दी समाचार देखिये, देखिये हिंदी में समाचार, Trending Video, Viral Video today"
                : "Hindi news Audio, audio story in hindi, breaking news podcast in hindi, latest news podcast in hindi, latest hindi news audio stories, Listen today news story in hindi, hindi news today, हिंदी न्यूज़ सुनिए, हिन्दी समाचार सुनिए, सुनिए हिंदी में समाचार, Trending audio stories, Viral podcast today"
            }`,
            url: `https://www.thelallantop.com${router.pathname}`,
            publisher: {
              "@type": "Organization",
              name: "TheLallantop",
              url: "https://www.thelallantop.com/",
              logo: {
                "@type": "ImageObject",
                url: "https://www.thelallantop.com/assets/images/lallatop-logo-m.svg",
                width: 600,
                height: 60,
              },
            },
          }),
        }}
      ></script>
      )
    </>
  );
};
export default HomePage;
